import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';

@Pipe({
  name: 'localTime',
  standalone: true,
})
export class LocalTimePipe implements PipeTransform {
  transform(value: Date, ...args: unknown[]): Date {
    // Convierte la fecha UTC a Moment.js
    const momentDate = moment.utc(value);

    // Obtén la zona horaria del cliente
    const clientTimezone = moment.tz.guess();

    // Convierte la fecha a la zona horaria del cliente
    const localDatetime = momentDate.tz(clientTimezone).toDate();

    return localDatetime;
  }
}
